export const productTypeConfig = {
  classTicket: { label: '堂票', value: 'classTicket' },
  externalWallet: { label: '會員餘額', value: 'externalWallet' },
  wallet: { label: '儲值金', value: 'wallet' },
  cashback: { label: '回饋金', value: 'cashback' },
  coupon: { label: '票券', value: 'coupon' },
  periodicBenefit: { label: '會員權益', value: 'periodicBenefit' },
  pointCard: { label: '點數卡', value: 'pointCard' },
  punchCard: { label: '次卡', value: 'punchCard' },
}

export const memberShopProductTypeConfig = {
  classTicket: { label: '堂票', value: 'classTicket' },
  externalWallet: { label: '會員餘額', value: 'externalWallet' },
  wallet: { label: '儲值金', value: 'wallet' },
  cashback: { label: '回饋金', value: 'cashback' },
  periodicBenefit: { label: '會員權益', value: 'periodicBenefit' },
  pointCard: { label: '點數卡', value: 'pointCard' },
  punchCard: { label: '次卡', value: 'punchCard' },
}

export const orderStatusConfig = {
  pending: { label: '確認中', value: 'pending', tagType: 'info' },
  confirmed: { label: '已確認', value: 'confirmed', tagType: 'warning' },
  finished: { label: '已完成', value: 'finished', tagType: 'action' },
  cancel: { label: '已取消', value: 'cancel', tagType: 'danger' },
  invalid: { label: '不成立', value: 'invalid', tagType: 'danger' },
}

export const orderPaymentStatusConfig = {
  unpaid: { label: '未付款', value: 'unpaid', tagType: 'info' },
  paid: { label: '已付款', value: 'paid', tagType: 'action' },
  readyToRefund: { label: '等待退款', value: 'readyToRefund', tagType: 'warning' },
  refunded: { label: '已退款', value: 'refunded', tagType: 'danger' },
  failed: { label: '付款失敗', value: 'failed', tagType: 'danger' },
  timeout: { label: '付款逾期', value: 'timeout', tagType: 'danger' },
  cancelled: { label: '取消授權', value: 'cancelled', tagType: 'danger' },
}

export const orderPaymentTypeConfig = {
  newebpay: { label: '藍新', value: 'newebpay', defaultLabel: '信用卡' },
  jkos: { label: '街口', value: 'jkos', defaultLabel: '街口支付' },
  linepay: { label: 'LINE Pay', value: 'linepay', defaultLabel: 'LINE Pay' },
  pchomepay: { label: '支付連', value: 'pchomepay', defaultLabel: '支付連' },
  sinoPacEpos: { label: '永豐EPOS', value: 'sinoPacEpos', defaultLabel: '信用卡' },
  wallet: { label: '儲值金', value: 'wallet', defaultLabel: '儲值金' },
  externalWallet: { label: '會員餘額', value: 'externalWallet', defaultLabel: '會員餘額' },
  free: { label: '免付款', value: 'free', defaultLabel: '免付款' },
  newebpayTrust: { label: '藍新（類信託）', value: 'newebpayTrust', defaultLabel: '信用卡' },
}

export const paymentlimitProductTypeConfig = {
  wallet: { label: '儲值金', value: 'wallet' },
  classTicket: { label: '堂票', value: 'classTicket' },
}

export const paymentPromiseVendorConfig = {
  sinoPac: { label: '永豐', value: 'sinoPac' },
}

export const invoiceStatusConfig = {
  notReady: { label: '尚未準備', value: 'notReady', tagType: 'info' },
  pending: { label: '未開立', value: 'pending', tagType: 'info' },
  issued: { label: '已開立', value: 'issued', tagType: 'action' },
  voided: { label: '全額作廢', value: 'voided', tagType: 'danger' },
  returned: { label: '全額折讓', value: 'returned', tagType: 'danger' },
}

export const productStatusConfig = {
  notReady: { label: '尚未準備', value: 'notReady', tagType: 'info' },
  pending: { label: '等待發送', value: 'pending', tagType: 'warning' },
  sent: { label: '已發送', value: 'sent', tagType: 'action' },
  readyToRecycle: { label: '準備收回', value: 'readyToRecycle', tagType: 'warning' },
  recycled: { label: '已回收', value: 'recycled', tagType: 'danger' },
  cancel: { label: '已取消', value: 'cancel', tagType: 'danger' },
}

export const logActionsCodeConfig = {
  ORDER_STATUS_CHANGED: { label: '訂單狀態改變', value: 'ORDER_STATUS_CHANGED' },
  ACT_ORDER_CANCELLED: { label: '操作訂單取消', value: 'ACT_ORDER_CANCELLED' },
  ACT_PAYMENT_PAID: { label: '付款單已付款', value: 'ACT_PAYMENT_PAID' },
  ACT_PAYMENT_FAILED: { label: '付款單付款失敗', value: 'ACT_PAYMENT_FAILED' },
  ACT_PAYMENT_TIMEOUT: { label: '付款單付款逾期', value: 'ACT_PAYMENT_TIMEOUT' },
  ACT_PAYMENT_REFUNDED: { label: '付款單已退款', value: 'ACT_PAYMENT_REFUNDED' },
  ACT_PRODUCT_SENT: { label: '商品已發送', value: 'ACT_PRODUCT_SENT' },
  ACT_PRODUCT_RECYCLED: { label: '商品已回收', value: 'ACT_PRODUCT_RECYCLED' },
  ACT_INVOICE_ISSUED: { label: '發票已開立', value: 'ACT_INVOICE_ISSUED' },
  ACT_INVOICE_VOIDED: { label: '發票已作廢', value: 'ACT_INVOICE_VOIDED' },
  ACT_INVOICE_RETURNED: { label: '發票已折讓', value: 'ACT_INVOICE_RETURNED' },
  ADMIN_REMARK: { label: '後台人員備註', value: 'ADMIN_REMARK' },
}

export const invoice3rdPlatformConfig = {
  ezpay: { label: 'ezpay', value: 'ezpay' },
}

export const giveawayStatusConfig = {
  notStarted: { label: '未上架', value: 'notStarted', tagType: 'info' },
  inProgress: { label: '進行中', value: 'inProgress', tagType: 'action' },
  finished: { label: '已結束', value: 'finished', tagType: 'danger' },
}

export const feedbackActivityStatusConfig = {
  enable: { label: '啟用', value: 'true', tagType: 'action' },
  disable: { label: '未啟用', value: 'false', tagType: 'info' },
}
